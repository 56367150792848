<template>
  <CMTransition mode="notification" :durationInMs="time">
    <div class="m-1 flex align-bottom">
      <p :class="classList">
        {{ msg }}
      </p>
    </div>
  </CMTransition>
</template>

<script>
import CMTransition from "./CMTransition.vue";

/**
 * Gute Idee: showFor - > die Zeit abängig machen von der Länge der Nachricht.. ?
 * Copied and pasted from creative.money booking system with love
 * say thanks to luca b4 you copy and paste to another project :)
 */
export default {
  components: {
    CMTransition,
  },
  props: {
    msg: {
      type: String,
      default: "",
    },
    success: {
      type: Boolean,
      default: true,
    },
    time: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      classList: "",
      classBase:
        "text-sm font-medium flex transition-all duration-500 ease-in-out rounded-xl border-2 border-gray-400 shadow-lg p-2 text-white ",
      show: false,
    };
  },
  async created() {
    let successClasses = "";
    if (this.success) {
      successClasses = "bg-green-600";
    } else {
      successClasses = "bg-red-500";
    }
    this.classList = this.classBase + " " + successClasses;
  },
};
</script>
