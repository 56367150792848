<template>
  <div>
    <progress
      :class="`progress w-full rounded-none ${progressVal}`"
      :value="progressVal"
      max="100"
    />
    <div class="flex justify-center">
      <div class="p-4 w-full sm:w-2/3 xl:w-1/2 text-center">
        <h1 class="text-xl font-bold mb-3">Fast Fertig!</h1>
        <h2>
          Das PDF wurde erfolgreich generiert und kann von Ihnen per Knopfdruck digital
          unterzeichnet werden!
        </h2>
        <div>
          <h3 class="font-bold mb-3 mt-2">
            Was Sie für die digitale Unterzeichnung benötigen:
          </h3>
          <div
            class="m-1 inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PhoneIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Telefon
          </div>
          <div
            class="m-1 inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <VideoCameraIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Webcam
          </div>
          <div
            class="m-1 inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <ClockIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            5-10 Minuten
          </div>
        </div>
        <h3 class="font-bold mb-3 mt-2">Bereit?</h3>
        <div v-if="isGemeinschaftskonto">
          <a :href="authUrl" target="__blank" class="btn btn-primary text-xs m-2"
            >Identifikation Person 1</a
          >
          <a :href="authUrl2" target="__blank" class="btn btn-primary text-xs m-2"
            >Identifikation Person 2</a
          >
        </div>
        <div v-else>
          <a :href="authUrl" target="__blank" class="btn btn-primary text-xs m-2"
            >IDENTIFIKATION STARTEN</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PhoneIcon, ClockIcon, VideoCameraIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
  props: {
    guid: {
      type: String,
      default: "",
    },
    isGemeinschaftskonto: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    authUrl() {
      return process.env.VUE_APP_BACKEND_DOMAIN + `start-crif.php?guid=${this.guid}`;
    },
    authUrl2() {
      return (
        process.env.VUE_APP_BACKEND_DOMAIN +
        `start-crif.php?guid=${this.guid}&gemeinschaftskonto=true`
      );
    },
  },
  data() {
    return {
      progressVal: "progress-primary",
    };
  },
};
</script>
