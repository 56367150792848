<template>
  <CMBreadCrumb
    :children="[
      { text: 'Statistik', link: '/admin/stats' },
      { text: 'Umfrage', link: '/admin/stats/umfrage' },
    ]"
  />
  <div class="h-screen">
    <h1 class="font-bold text-lg">Umfrage Ergebnisse:</h1>
    <div class="flex justify-center flex-col md:flex-row flex-wrap">
      <div class="w-full flex justify-center py-2 flex-1 flex-col">
        <div>
          <p>Zeitraum aussuchen:</p>
        </div>
        <VDatePicker v-model.range="range" mode="date" />
      </div>
      <div class="h-2/3 sm:h-1/3 flex-1">
        <div>
          <p>Statistik für diesen Zeitraum:</p>
        </div>
        <div class="w-full flex-col bg-slate-100 rounded shadow border">
          <div class="p-4">
            <div class="m-2" v-if="ready">
              <Doughnut :data="{ labels, datasets }" :options="options" />
            </div>
            <LoadingCircle v-else />
            <p class="text-center text-xs my-2">n = {{ umfrage.length }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-scroll mt-4 pb-8">
      <table
        v-if="umfrage.length > 0"
        class="table-auto text-left min-w-full divide-y divide-gray-300 py-4"
      >
        <thead>
          <tr class="text-sm font-semibold text-gray-900">
            <th scope="col" class="px-3 py-3.5">Datum</th>
            <th scope="col" class="px-3 py-3.5">Bestehender Kunde</th>
            <th scope="col" class="px-3 py-3.5">Zeitung</th>
            <th scope="col" class="px-3 py-3.5">Internet</th>
            <th scope="col" class="px-3 py-3.5">Durchblicker</th>
            <th scope="col" class="px-3 py-3.5">Freunde</th>
            <th scope="col" class="px-3 py-3.5">Sonstige</th>
            <th scope="col" class="px-3 py-3.5">Sonstige Text</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr v-for="row in umfrage" :key="row">
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ bd(row.created_at) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ mb(row.bestehender_kunde) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ mb(row.zeitung) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ mb(row.internet) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ mb(row.durchblicker) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ mb(row.freunde) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ mb(row.sonstige) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ row.sonstige_text }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="font-boold">
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              Total:
            </td>
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              {{ datasets[0].data[0] }}
            </td>
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              {{ datasets[0].data[1] }}
            </td>
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              {{ datasets[0].data[2] }}
            </td>
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              {{ datasets[0].data[3] }}
            </td>
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              {{ datasets[0].data[4] }}
            </td>
            <td class="whitespace-nowrap px-3 font-bold py-4 text-sm text-gray-500">
              {{ datasets[0].data[5] }}
            </td>
          </tr>
        </tfoot>
      </table>
      <p v-else>Keine Daten verfügbar für den gewählten Zeitraum</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import shared from "@/shared";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import LoadingCircle from "@/components/LoadingCircle.vue";
import CMBreadCrumb from "@/components/CMBreadCrumb.vue";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  data() {
    return {
      ready: false,
      range: {
        start: new Date(),
        end: undefined,
      },
      umfrage: [],
      labels: [
        "Bestehender Kunde",
        "Zeitung",
        "Internet",
        "Durchblicker",
        "Freunde",
        "Sonstige",
      ],
      datasets: [
        {
          backgroundColor: [
            "#41B883",
            "#E46651",
            "#00D8FF",
            "#DD1B16",
            "#424242",
            "#000",
          ],
          data: [0, 0, 0, 0, 0, 0],
        },
      ],
      options: { responsive: true, maintainAspectRatio: false },
    };
  },
  components: {
    Doughnut,
    CMBreadCrumb,
    LoadingCircle,
  },
  methods: {
    bd(d) {
      return shared.beautifyDate(new Date(d), true);
    },
    // make bool beautiful
    mb(x) {
      if (x == 1) {
        return "✔️";
      }
      return "❌";
    },
    loadStats() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_DOMAIN +
            `info-api.php?start=${this.bd(this.range.start)}&end=${this.bd(
              this.range.end
            )}`,
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          this.$store.dispatch("newResponse", {
            message: "Umfrage geladen",
            success: true,
          });
          this.umfrage = res.data;

          // reset data:
          this.datasets[0].data = [0, 0, 0, 0, 0, 0];
          this.umfrage.forEach((eingabe) => {
            if (eingabe.bestehender_kunde == "1") {
              this.datasets[0].data[0]++;
            }
            if (eingabe.zeitung == "1") {
              this.datasets[0].data[1]++;
            }
            if (eingabe.internet == "1") {
              this.datasets[0].data[2]++;
            }
            if (eingabe.durchblicker == "1") {
              this.datasets[0].data[3]++;
            }
            if (eingabe.freunde == "1") {
              this.datasets[0].data[4]++;
            }
            if (eingabe.sonstige == "1") {
              this.datasets[0].data[5]++;
            }
          });

          this.labels = [
            `Bestehender Kunde (${this.datasets[0].data[0]})`,
            `Zeitung (${this.datasets[0].data[1]})`,
            `Internet (${this.datasets[0].data[2]})`,
            `Durchblicker (${this.datasets[0].data[3]})`,
            `Freunde (${this.datasets[0].data[4]})`,
            `Sonstige (${this.datasets[0].data[5]})`,
          ];

          this.ready = true;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Ein Fehler ist aufgetreten",
            success: false,
          });
        });
    },
  },
  watch: {
    range: {
      handler() {
        this.ready = false;
        this.loadStats();
      },
      deep: true,
    },
  },
  created() {
    this.loadStats();
  },
};
</script>
