<template>
  <div>
    <TransitionRoot as="template" :show="transitionHappening">
      <TransitionChild
        as="template"
        :enter="tCs.enter"
        :enter-from="tCs.enterFrom"
        :enter-to="tCs.enterTo"
        :leave="tCs.leave"
        :leave-from="tCs.leaveFrom"
        :leave-to="tCs.leaveTo"
      >
        <slot />
      </TransitionChild>
    </TransitionRoot>
  </div>
</template>

<script setup>
// provided by headlessui and creative.money 
import { TransitionChild, TransitionRoot } from "@headlessui/vue"; // leave this!
</script>

<script>
import shared from "@/shared.js";
export default {
  data() {
    return {
      tCs: {}, // transitionClasses
      internalShow: false,
      loaded: false,
      transitionHappening: false,
    };
  },
  props: {
    mode: {
      type: String,
      default: "simple",
    },
    durationInMs: {
      type: Number,
      default: 1200,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    console.log(`Created CM-Transition : ${this.mode}`);
    if (this.mode == "simple") {
      this.tCs = {
        enter: "transition-opacity duration-75",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "transition-opacity duration-150",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
      };
    } else if (this.mode == "spin-in") {
      this.tCs = {
        enter: "transform transition duration-[400ms]",
        enterFrom: "opacity-0 rotate-[-120deg] scale-50",
        enterTo: "opacity-100 rotate-0 scale-100",
        leave: "transform duration-200 transition ease-in-out",
        leaveFrom: "opacity-100 rotate-0 scale-100",
        leaveTo: "opacity-0 scale-95",
      };
    } else {
      this.tCs = {
        enter: "transform ease-out duration-300 transition",
        enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
        enterTo: "translate-y-0 opacity-100 sm:translate-x-0",
        leave: "transition ease-in duration-100",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
      };
    }

    await shared.sleep(100);
    this.transitionHappening = true;

    await shared.sleep(this.durationInMs - 390);
    this.transitionHappening = false;
  },
};
</script>
