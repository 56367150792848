<template>
  <section class="relative pt-2">
    <div v-if="application.stepsLoaded < 8">
      <span
        >Erreichte Schritte (<i>{{ stepsToText(application.stepsLoaded) }}</i
        >)</span
      >
      <progress
        :class="`progress progress-primary w-full rounded-none`"
        :value="application.stepsLoaded"
        max="8"
      />
    </div>
    <div v-else>
      <h1 class="font-bold text-white bg-primary rounded shadow p-1 text-center">
        Abgeschlossener Prozess
      </h1>
    </div>
    <div v-if="showMark">
      <span
        v-if="marked"
        @click="$emit('star')"
        class="absolute rotate-180 hover:rotate-0 bg-primary transition-all -right-5 -top-5 p-2 rounded-full hover:bg-blue-200 hover:cursor-pointer"
        ><StarIcon class="h-5 w-5"
      /></span>
      <span
        v-else
        @click="$emit('star')"
        class="absolute hover:rotate-180 bg-blue-200 transition-all -right-5 -top-5 p-2 rounded-full hover:bg-primary hover:cursor-pointer"
        ><StarIcon class="h-5 w-5"
      /></span>
    </div>
  </section>
</template>

<script setup>
import { StarIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import shared from "@/shared.js";

export default {
  emits: ["star"],
  props: {
    application: {
      type: Object,
      default: () => {},
    },
    marked: {
      type: Boolean,
      default: false,
    },
    showMark: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    marked(nM, oM) {
      console.log(nM, oM);
    },
  },
  methods: {
    stepsToText(s) {
      let steps = shared.getStepsArray();
      return steps[s - 1];
    },
  },
};
</script>
