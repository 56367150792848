<template>
  <div
    v-if="$route.query.country == 'de'"
    class="flex align-middle items-center"
  >
    <div>
      <div
        v-for="(item, i) in info"
        :key="i"
        class="flex justify-between align-middle my-2"
      >
        <CMInput
          :disabled="disabled"
          :label="`Steuernummer (${item.country})`"
          :model-value="`${info[i].country}: ${info[i].id}`"
        />
        <button
          v-if="!disabled"
          class="text-xs text-red-400"
          type="button"
          @click="removeItem(i)"
        >
          Entfernen
        </button>
      </div>
      <div v-if="!disabled">
        <div v-if="info.length == 0 || addAnother" class="my-2">
          <CountryDropdown
            label="In diesem Land bin ich ebenfalls steuerpflichtig"
            v-model="tmpInfo.country"
          />
          <div v-if="addAnother">
            <CMInput
              type="text"
              v-model="tmpInfo.id"
              label="Steuer ID Nummer"
              :required="true"
            />
            <button class="btn btn-primary mt-4" type="button" @click="addWithCheck">
              Hinzufügen
            </button>
          </div>
        </div>
        <div v-if="!addAnother && !disabled" class="my-2">
          <button class="btn btn-primary mt-4" type="button" @click="addAnother = true">
            Weitere Steuernummer angeben
          </button>
        </div>
        <div v-else>
          <button class="btn btn-primary mt-4" type="button" @click="addAnother = false">
            Doch nicht
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CMInput from "./CMInput.vue";
import CountryDropdown from "./CountryDropdown.vue";

export default {
  data() {
    return {
      info: [],
      tmpInfo: {
        country: "",
        id: "",
      },
      addAnother: false,
    };
  },
  props: {
    initialInfo: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addSteuerLandInfo(country, taxid) {
      this.info.push({ country: country, id: taxid });
    },
    addWithCheck() {
      for (let item of this.info) {
        console.log(this.tmpInfo.country, item.country);
        if (this.tmpInfo.country == item.country) {
          console.log("Trying to abort with useful message");
          this.$store.dispatch("newResponse", {
            message:
              "Für dieses Land besteht bereits eine Auskunft. Bitte löschen Sie die andere Information über dieses Land und fügen Sie sie erneut hinzu, wenn Sie die Nummer ändern möchten.",
            success: false,
            time: 10000,
          });
          return;
        }
      }

      if (this.tmpInfo.country != "" && this.tmpInfo.id != "") {
        console.log("Adding new Steuer NR Info");
        this.addSteuerLandInfo(this.tmpInfo.country, this.tmpInfo.id);
        this.tmpInfo.id = ""; // country can not be reset...
        this.$store.dispatch("newResponse", {
          message: "Erfolgreich Information hinzugefügt",
          success: true,
        });
        this.addAnother = false;
        this.$emit("update:modelValue", this.info);
      }
    },
    removeItem(n) {
      this.info.splice(n, 1);
      this.$store.dispatch("newResponse", {
        message: "Erfolgreich entfernt",
        success: true,
        time: 1500,
      });
    },
  },
  created() {
    if (this.initialInfo) {
      if (this.initialInfo.length > 0) {
        this.info = this.initialInfo;
      }
    }
  },
  components: { CMInput, CountryDropdown },
};
</script>
