<template>
  <div class="my-2" v-if="zinsSetting">
    <router-link :to="'/admin/zinsen/' + zinsSetting.id">
      <div
        class="border border-orange-200 rounded-lg bg-orange-50 p-2 my-1 hover:bg-orange-100 hover:cursor-pointer transition-all"
      >
        <p class="font-bold">Zinssatz #{{ zinsSetting.id }}</p>
        <p>Modus: {{ zinsSetting.mode }}</p>
        <p>
          Ersteller: {{ zinsSetting.createdBy.firstName }}
          {{ zinsSetting.createdBy.lastName }} ({{ bd(zinsSetting.createdAt) }})
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import shared from "@/shared.js";

export default {
  props: {
    zinsSetting: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    bd(d) {
      return shared.beautifyDate(new Date(d));
    },
  },
  created() {
    console.log("I WAS CREATED", this.zinsSetting);
  },
};
</script>
