<template>
  <div class="flex justify-center">
    <div class="p-4 w-full sm:w-2/3 xl:w-1/2">
      <Ertragsrechner />
    </div>
  </div>
</template>

<script>
import Ertragsrechner from "@/components/Ertragsrechner.vue";

export default {
  components: {
    Ertragsrechner,
  },
};
</script>
