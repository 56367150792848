<template>
  <div
    class="m-2 sm:m-6 my-1 bottom-0 right-0 flex flex-col align-bottom fixed z-50"
  >
    <Response
      v-for="response in responses"
      :key="response"
      :msg="response.message"
      :success="response.success"
      :time="response.time"
    />
  </div>
</template>

<script>
/**
 * This component was created for another project
 * And copied here.
 * Feel free to use inside this project but..
 * dont copy and paste to another project
 * without saying thx to luca :)
 */
import Response from "@/components/Response.vue";

export default {
  components: {
    Response,
  },
  computed: {
    responses() {
      return this.$store.getters.getResponses;
    },
  },
  watch: {
    responses() {
      console.log("Responses have changed");
    },
  },
  created() {
    // reset
    this.$store.state.responses = [];
  },
};
</script>
