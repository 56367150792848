<template>
  <div>
    <EasyNav />
    <div class="p-6">
      <h1 class="text-lg font-bold">Auftrag zur anlassbezogenen Abfrage des Kirchensteuermerkmals („KISTAM“)</h1>
      Ich/wir beauftrage(n) die Bank mit der Abfrage meines/unseres Kirchensteuermerkmals
      beim Bundeszentralamt für Steuern (BZSt) zum 01.01. des Folgejahres. Sofern im
      laufenden Jahr noch keine Ertragsgutschrift erfolgt ist, gilt der Auftrag ab sofort.
      Die nachfolgenden Hinweise zur Kirchensteuer auf private Kapitalerträge habe(n)
      ich/wir zur Kenntnis genommen.

      <h2 class="font-bold pt-3">Allgemeine Hinweise zum Kirchensteuerverfahren</h2>
      Ab 01.01.2015 sind die Banken gesetzlich verpflichtet, die auch bisher fällige
      Kirchensteuer auf die Abgeltungsteuer automati sch an das Finanzamt abzuführen.
      Liegt ein ausreichender Freistellungsauftrag oder eine NV-Bescheinigung vor, wird
      keine Kirchensteuer abgeführt. Das betrifft Sie nur, wenn Sie Mitglied einer
      steuererhebenden Religionsgemeinschaft sind. Ansonsten besteht kein Handlungsbedarf.
      Um den Kirchensteuerabzug vornehmen zu können, müssen wir beim Bundeszentralamt für
      Steuern (BZSt) abfragen, ob Sie einer Religionsgemeinschaftangehören. Dies erfolgt
      jährlich zwischen dem 01. September und 31. Oktober Regelabfrage . Die Auskunft
      erhalten wir verschlüsselt in Form eines Kirchensteuerabzugsmerkmals (KiStAM), so
      dass der Datenschutz gewahrt ist.<br />
      <b>Ihr Vorteil</b>: Ihre Kirchensteuerpflicht auf Kapitalerträge ist abgegolten.
      Weitere Angaben in der Steuererklärung entfallen. <br />
      <b>Wozu dient die Anlassabfrage?</b> Die Anlassabfrage ergänzt die jährliche
      Regelabfrage. Das Ergebnis der Regelabfrage wird beim Steuereinbehalt des
      Folgejahres berücksichtigt. Damit für Sie bereits im laufenden Jahr der
      Kirchensteuereinbehalt sichergestellt werden kann, ist eine sofortige Abfrage
      möglich. Bitte beachten Sie: Wenn für Sie im laufenden Jahr bereits Kapitalerträge
      gutgeschrieben wurden, erfolgt die Abfrage stets mit Wirkung für das Folgejahr.
      <br />
      <h2 class="pt-3 font-bold">Widerspruchsrecht gegenüber dem BZSt</h2>
      Wenn Sie nicht möchten, dass das BZSt Ihre Daten übermittelt, können Sie der
      Datenweitergabe gegenüber dem BZSt widersprechen. Der Widerspruch muss spätestens
      zwei Monate vor der KiStAM-Abfrage beim BZSt eingelegt werden (im Fall der
      Regelabfrage daher bis spätestens 30.06.). Ein einmal eingelegter Widerspruch gilt
      bis zu seinem Widerruf. Das BZSt meldet Ihren Widerspruch dem Finanzamt, das Sie
      dann zur Abgabe einer Steuererklärung bzgl. der Kirchensteuer auffordern wird. Ihren
      Widerspruch richten Sie bitte direkt an das BZSt, An der Küppe 1, 53225 Bonn,
      Telefon 0228 406-1240 Das entsprechende Formular finden Sie unter
      www.formulare-bfinv.de unter dem Stichwort „Kirchensteuer“ Rechtsgrundlagen für
      dieses Verfahren: § 51a Abs. 2c, 2e Einkommensteuergesetz; Kirchensteuergesetze der
      Länder.
    </div>
  </div>
</template>

<script>
import EasyNav from "@/components/EasyNav.vue";
export default {
  components: { EasyNav },
};
</script>
