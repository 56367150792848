<template>
  <section class="bg-gray-50">
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div class="flex items-center mb-6 text-2xl font-semibold">
        <img
          class="w-12 h-12 mr-2"
          src="@/assets/cropped-favicon.png"
          alt="Vakifbank Icon"
        />
      </div>
      <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl"
          >
            {{ infoText.mode }}
          </h1>
          <form
            class="space-y-4 md:space-y-6"
            @submit="loginOrSignUp"
            @submit.prevent="onSubmit"
          >
            <CMInput
              v-model="email"
              label="E-Mail"
              type="email"
              :required="true"
              description="Ihre E-Mail Adresse"
            />
            <div class="flex">
              <CMInput
                v-if="!login"
                class="flex-1 mr-1"
                v-model="firstName"
                label="Vorname"
                :required="true"
                description="Ihr Vorname"
              /><CMInput
                v-if="!login"
                class="flex-1 ml-1"
                v-model="lastName"
                label="Nachname"
                :required="true"
                description="Ihr Nachname"
              />
            </div>
            <CMInput
              v-if="login"
              v-model="password"
              label="Passwort"
              type="password"
              :required="true"
            />
            <CMInput
              v-else
              v-model="password"
              autocomplete="new-password"
              minlength="12"
              label="Neues Passwort"
              description="Wähle ein sicheres Passwort mit speziellen Zeichen. Es muss mindestens 12 Charaktäre lang sein."
              type="password"
              :required="true"
            />
            <div class="flex items-center justify-between">
              <div v-if="login" class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500">Angemeldet bleiben</label>
                </div>
              </div>
              <a
                v-if="login"
                href="#"
                class="text-sm font-medium text-primary-600 hover:underline"
                >Passwort vergessen?</a
              >
            </div>
            <button
              type="submit"
              class="w-full text-white bg-primary hover:bg-opacity-70 transition-all focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {{ infoText.mode }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import CMInput from "@/components/CMInput.vue";

export default {
  components: {
    CMInput,
  },
  data() {
    return {
      login: true,
      firstName:"",
      lastName: "",
      email: "",
      password: "",
    };
  },
  computed: {
    infoText() {
      let response = {};
      if (this.login) {
        response.mode = "Anmelden";
        response.switchExplanation = "Noch keinen Account?";
        response.opposite = "Registrieren";
      } else {
        response.mode = "Registrieren";
        response.switchExplanation = "Bereits registriert?";
        response.opposite = "Anmelden";
      }

      return response;
    },
  },
  methods: {
    loginOrSignUp() {
      if (this.login) {
        this.sendLogin();
      } else {
        this.sendSignUp();
      }
    },
    async sendLogin() {
      axios
        .post(process.env.VUE_APP_ADMIN_BACKEND + "login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          console.log(res);

          this.$store.commit("setJwt", res.data.jwt);

          this.$store.dispatch("newResponse", {
            message: "Erfolgreich angemeldet",
            success: true,
          });

          this.$router.push("/admin");
        })
        .catch((e) => {
          console.log(e);
          let message;
          try {
            message = e.response.data.msg;
          } catch (err) {
            message = "Unbekannter fehler";
          }
          this.$store.dispatch("newResponse", {
            message: message,
            success: false,
          });
        });
    },
    async sendSignUp() {
      axios
        .post(
          process.env.VUE_APP_ADMIN_BACKEND + "user",
          {
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
          },
          {
            headers: {
              "registration-allowance": this.$route.query.registrationAllowance,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich angemeldet",
            success: true,
          });

          if (res.data.jwt) {
            this.$store.commit("setJwt", res.data.jwt);
            this.$router.push("/admin");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: e.response.data.msg,
            success: false,
          });
        });
    },
  },
  created() {
    if (this.$route.query.registrationAllowance) {
      // dont check directly. lets assume it's okay. if it's not okay its better we collect the e-mail associated with the try.
      this.login = false;
    }
  },
};
</script>
