<template>
  <div>
    <EasyNav />
    <div class="p-4">
      <h1 class="font-bold">Widerrufsbelehrung</h1>
      <p>
        Widerrufsbelehrung bei außerhalb von Geschäftsräumen geschlossenen Verträgen und
        bei Fernabsatzverträgen über Finanzdienstleistungen.
      </p>
      <h1 class="font-bold">Widerrufsrecht</h1>
      <p>
        Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen
        mittels einer eindeutigen Erklärung widerrufen. Die Frist beginnt nach Erhalt
        dieser Belehrung auf einem dauerhaften Datenträger, jedoch nicht vor
        Vertragsschluss und auch nicht vor Erfüllung unserer Informationspflichten gemäß
        Artikel 246b § 2 Absatz 1 in Verbindung mit Artikel 246b § 1 Absatz 1 EGBGB. Zur
        Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs, wenn
        die Erklärung auf einem dauerhaften Datenträger (z. B. Brief, Telefax, E-Mail)
        erfolgt. Der Widerruf ist zu richten an: VakifBank International AG, Wien
        Zweigniederlassung Deutschland Alter Markt 54 50667 Köln Fax: +49 (0)221 / 258 94
        -27 E-Mail: infokoeln@vakif-bank.de
      </p>
      <h1 class="font-bold">Widerrufsfolgen</h1>
      <p>
        Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen
        zurückzugewähren. Sie sind zur Zahlung von Wertersatz für die bis zum Widerruf
        erbrachte Dienstleistung verpflichtet, wenn Sie vor Abgabe Ihrer Vertragserklärung
        auf diese Rechtsfolge hingewiesen wurden und ausdrücklich zugestimmt haben, dass
        wir vor dem Ende der Widerrufsfrist mit der Ausführung der Gegenleistung beginnen.
        Besteht eine Verpflichtung zur Zahlung von Wertersatz, kann dies dazu führen, dass
        Sie die vertraglichen Zahlungsverpflichtungen für den Zeitraum bis zum Widerruf
        dennoch erfüllen müssen. Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag
        von beiden Seiten auf Ihren ausdrücklichen Wunsch vollständig erfüllt ist, bevor
        Sie Ihr Widerrufsrecht ausgeübt haben. Verpflichtungen zur Erstattung von
        Zahlungen müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie
        mit der Absendung Ihrer Widerrufserklärung, für uns mit deren Empfang.<br />
        Besondere Hinweise: Keine
      </p>
    </div>
  </div>
</template>

<script>
import EasyNav from "@/components/EasyNav.vue";
export default {
  components: { EasyNav },
};
</script>
