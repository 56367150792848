<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full">
    <Disclosure as="nav" class="bg-slate-50" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img
                class="h-8 w-8"
                src="@/assets/cropped-favicon.png"
                alt="Icon Vakifbank"
              />
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    isCurrentlyActive(item.href)
                      ? 'bg-primary text-white scale-105 transition-all'
                      : 'hover:bg-primary hover:bg-opacity-20 border-primary border-2 text-primary hover:scale-105 transition-all',
                    'rounded-md px-3 py-2 text-sm font-medium',
                  ]"
                  :aria-current="isCurrentlyActive(item.href) ? 'page' : undefined"
                  >{{ item.name }}</router-link
                >
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <button
                type="button"
                class="rounded-full bg-primary p-1 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary"
              >
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button>

              <!-- Profile dropdown -->
              <Menu as="div" class="relative ml-3">
                <div>
                  <MenuButton
                    class="flex max-w-xs items-center rounded-full bg-primary text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="h-8 w-8 rounded-full invert"
                      src="@/assets/unknown-profile.png"
                      alt=""
                    />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    :v-show="isOpen"
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item"
                      @click="navigate"
                    >
                      <router-link
                        :to="item.href"
                        :class="[
                          isCurrentlyActive(item.href) ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >{{ item.name }}</router-link
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button
              @click="isOpen = !isOpen"
              class="inline-flex items-center justify-center rounded-md bg-primary p-2 text-white hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <div v-if="isOpen" class="md:hidden">
        <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          <div v-for="item in navigation" :key="item.name">
            <router-link :to="item.href" @click="navigate">
              <div
                :class="[
                  isCurrentlyActive(item.href)
                    ? 'bg-primary text-white'
                    : 'hover:bg-primary hover:bg-opacity-75 border-secondary border-2 text-primary',
                  'block rounded-md px-3 py-2 text-base font-medium',
                ]"
                :aria-current="isCurrentlyActive(item.href) ? 'page' : undefined"
              >
                {{ item.name }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="border-t border-primary pb-3 pt-4">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img
                class="h-10 w-10 rounded-full invert"
                src="@/assets/unknown-profile.png"
                alt=""
              />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-primary">
                {{ $store.getters.getJwtDecoded.name }}
              </div>
              <div class="text-sm font-medium text-secondary underline">
                {{ $store.getters.getJwtDecoded.email }}
              </div>
            </div>
            <button
              type="button"
              class="ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-primary p-1 text-white hover:oultline-secondary focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-3 space-y-1 px-2">
            <div v-for="item in userNavigation" :key="item.name">
              <router-link :to="item.href">
                <div
                  class="block rounded-md px-3 py-2 text-base font-medium text-primary hover:bg-primary hover:bg-opacity-75 hover:text-white"
                >
                  {{ item.name }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>

    <header class="bg-white shadow" v-if="$route.meta.title">
      <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          {{ $route.meta.title }}
        </h1>
      </div>
    </header>
    <main>
      <div class="sm:mx-auto sm:max-w-7xl mx-5 py-6 sm:px-6 lg:px-8">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script setup>
import { Disclosure, Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import shared from "@/shared.js";

export default {
  data() {
    return {
      userNavigation: [],
      isOpen: false,
      navigation: [],
    };
  },
  computed: {
    lastApplicationGuid() {
      console.log("LatestApplicationGuid changed");
      return this.$store.state.lastApplicationGuid;
    },
    currentRoute() {
      return this.$route.fullPath;
    },
  },
  watch: {
    async lastApplicationGuid() {
      await shared.sleep(500);
      this.redoNavigation();
    },
  },
  methods: {
    isCurrentlyActive(linkTo) {
      return this.currentRoute.endsWith(linkTo);
    },
    async navigate() {
      this.isOpen = false;
      await shared.sleep(100); // give the router some time...
      this.redoNavigation();
    },
    redoNavigation() {
      console.log("Redoing navigation...");
      this.navigation = [
        { name: "Dashboard", href: "/admin" },
        {
          name: "Anträge",
          href: "/admin/applications",
        },
        {
          name: "Letzter Antrag",
          href: "/admin/application/" + this.lastApplicationGuid,
        },
        { name: "Mitarbeiter", href: "/admin/accounts" },
        { name: "Statistik", href: "/admin/stats" },
      ];
      if (this.$store.getters.getJwtDecoded.isAdmin) {
        this.navigation.push({ name: "Zinssätze", href: "/admin/zinsen" });
      }
    },
  },
  created() {
    this.redoNavigation();
    this.userNavigation = [
      {
        name: "Mein Profil",
        href: "/admin/account/" + this.$store.getters.getJwtDecoded.guid,
      },
      { name: "Abmelden", href: "/logout" },
    ];
  },
};
</script>
