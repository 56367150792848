<template>
  <CMBreadCrumb :children="[{ text: 'Statistik', link: '/admin/stats' }]" />
  <div>
    <h1 class="text-lg font-bold">Verfügbare Statistik Instrumente:</h1>
    <div class="flex py-2 flex-col">
      <router-link
        to="/admin/stats/umfrage"
        class="my-2 inline-flex transition-all items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
      >
        <ChartPieIcon class="h-6 w-6 m-2" aria-hidden="true" />
        Zu der Marketing-Umfrage
      </router-link>
      <router-link
        to="/admin/stats/funnel"
        class="my-2 inline-flex transition-all items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
      >
        <ChartBarIcon class="h-6 w-6 m-2" aria-hidden="true" />
        Step for Step (Funnel) Analyse
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ChartBarIcon, ChartPieIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import CMBreadCrumb from "@/components/CMBreadCrumb.vue";

export default {
  components: {
    CMBreadCrumb,
  },
};
</script>
