<template>
  <CMBreadCrumb :children="[{ text: 'Accounts', link: '/admin/accounts' }]" />
  <h3 class="mb-2 font-bold">Alle Accounts ({{ users.length }}):</h3>
  <div class="my-4" v-for="user in users" :key="user">
    <router-link :to="`/admin/account/${user.guid}`">
      <User :user="user" />
    </router-link>
  </div>
  <section
    class="text-center bg-slate-50 border rounded shadow p-4 my-2"
    v-if="$store.getters.getJwtDecoded.isAdmin"
  >
    <div class="flex justify-center">
      <UserGroupIcon class="h-20 w-20" aria-hidden="true" />
    </div>
    <h3 class="mt-2 text-sm font-semibold text-gray-900">Neuen Account anlegen</h3>
    <p class="mt-1 text-sm text-gray-500">
      Zugang für einen weiteren Mitarbeiter erstellen.
    </p>
    <div class="mt-6">
      <button
        @click="showModal = !showModal"
        type="button"
        class="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <UserPlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        Neuer Account
      </button>
      <CustomModal :show="showModal">
        <form
          class="p-4"
          @submit="createRegistrationAllowance"
          @submit.prevent="onSubmit"
        >
          <h3 class="mt-4 text-lg font-bold p-1">Registrierung erlauben</h3>
          <CMInput
            label="E-Mail des neuen Accounts"
            v-model="newUserEmail"
            type="email"
            :required="true"
            description="Diese E-Mail wird die Erlaubnis erhalten, sich einen Account zu erstellen."
          />
          <button
            type="submit"
            class="inline-flex mt-2 items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <UserPlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Registrierungserlaubnis erstellen
          </button>
        </form>
      </CustomModal>
    </div>
    <div id="regallwnces" class="mt-3">
      <h4 class="font-bold text-sm">
        Gültige Registrierungs-Erlaubnisse ({{ registrationAllowances.length }}):
      </h4>
      <div v-for="rA in registrationAllowances" :key="rA">
        <div class="border-2 rounded-lg shadow p-2 my-2 text-left">
          <div>Email: {{ rA.email }}</div>
          <div>Gültig bis: {{ bd(rA.validUntil) }}</div>
          <div>Ersteller: {{ rA.createdBy }}</div>
          <div>
            Benutzt: <span class="text-xs">{{ rA.used ? "✔️" : "❌" }} </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { UserPlusIcon } from "@heroicons/vue/20/solid";
import { UserGroupIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import CustomModal from "@/components/CustomModal.vue";
import CMInput from "@/components/CMInput.vue";
import User from "@/components/User.vue";

import axios from "axios";
import shared from "@/shared";
import CMBreadCrumb from "@/components/CMBreadCrumb.vue";

export default {
  data() {
    return {
      users: [],
      showModal: false,
      newUserEmail: "",
      registrationAllowances: [],
    };
  },
  components: {
    User,
    CMInput,
    CustomModal,
    CMBreadCrumb,
  },
  methods: {
    createRegistrationAllowance() {
      axios
        .put(
          process.env.VUE_APP_ADMIN_BACKEND + "registrationAllowance",
          {
            email: this.newUserEmail,
          },
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich erlaubnis erstellt",
            success: true,
          });
          this.loadRegistrationAllowances();
          this.showModal = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Ein Fehler ist aufgetreten",
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
    loadRegistrationAllowances() {
      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + "registrationAllowances", {
          headers: {
            "x-access-token": this.$store.getters.getJwt,
          },
        })
        .then((res) => {
          let message = `Erfolgreich ${res.data.length} Registrierungserlaubnisse geladen`;
          if (res.data.length == 0) {
            message = "Aktuell keine gültigen Registrierungserlaubnisse vorhanden 👍🏼";
          }
          this.$store.dispatch("newResponse", {
            message,
            success: true,
          });

          this.registrationAllowances = res.data;
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
    bd(x) {
      return shared.beautifyDate(new Date(x), false, false);
    },
    loadUsers() {
      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + "user", {
          headers: {
            "x-access-token": this.$store.getters.getJwt,
          },
        })
        .then((res) => {
          this.users = res.data;
          this.$store.dispatch("newResponse", {
            message: "Accounts erfolgreich geladen",
            success: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Accounts konnten nicht geladen werden",
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
  },
  created() {
    this.loadUsers();
    if (this.$store.getters.getJwtDecoded.isAdmin) {
      this.loadRegistrationAllowances();
    }
  },
};
</script>
