<template>
  <div>
    <div v-if="markedApps.length > 0" class="p-2 bg-gray-50 rounded">
      <h1 class="font-bold">Markierte Anträge ({{ markedApps.length }})</h1>
      <Application v-for="app in markedApps" :key="app" :application="app" />
    </div>
    <div v-else>
      <h2 class="font-bold italic text-lg">Keine markierten Anträge</h2>
      <p class="text-sm">
        Wenn du Anträge markierst, scheinen hier deine markierten hier Anträge auf
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Application from "@/components/Application.vue";
export default {
  components: {
    Application,
  },
  data() {
    return {
      markedApps: [],
    };
  },
  methods: {
    loadMarked() {
      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + "application/marked", {
          headers: {
            "x-access-token": this.$store.getters.getJwt,
          },
        })
        .then((res) => {
          console.log(res);
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich markierte Anträge geladen",
            success: true,
          });

          res.data.forEach((application) => {
            this.markedApps.push(application.application.rawData);
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Konnte markierte Anträge nicht laden",
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
  },
  created() {
    this.loadMarked();
  },
};
</script>
