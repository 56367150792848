<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4 pb-3">
      <li>
        <div>
          <router-link to="/admin" class="text-gray-400 hover:text-gray-500">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <div v-for="index in children.length" :key="index">
        <li>
          <div class="flex items-center">
            <ChevronRightIcon
              class="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <router-link
              v-if="index == children.length"
              :to="children[index - 1].link"
              class="underline ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >{{ children[index - 1].text }}</router-link
            >
            <router-link
              v-else
              :to="children[index - 1].link"
              class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >{{ children[index - 1].text }}</router-link
            >
          </div>
        </li>
      </div>
    </ol>
  </nav>
</template>

<script setup>
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    console.log(this.children);
  },
};
</script>
