<template>
  <TransitionRoot
    :show="active"
    enter="transition-opacity duration-75"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div :class="classListParent" style="background-color: rgba(0, 0, 0, 0.4)">
      <div
        id="popup-modal"
        tabindex="-1"
        class="overflow-y-auto overflow-x-hidden top-0 right-0 left-0 md:inset-0 h-modal md:h-full"
      >
        <div class="relative p-4 w-full max-w-lg h-full md:h-auto align-middle">
          <div class="relative bg-white rounded-lg shadow p-2">
            <button
              type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="popup-modal"
              @click="toggleActive"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div>
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup>
import { TransitionRoot } from "@headlessui/vue";
</script>

<script>
// copied from creative.money booking system.
// dont just copy this to another software project without saying thanks to luca
export default {
  props: ["text", "okText", "declineText", "show", "headerText"],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classListParent() {
      if (this.active) {
        return "z-50 fixed flex w-screen h-screen top-0 right-0 left-0 justify-center align-middle backdrop-blur-sm p-10";
      } else {
        return "hidden";
      }
    },
  },
  watch: {
    show() {
      this.toggleActive();
    },
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    emitOkFunction() {
      this.$emit("okFunction");
    },
  },
};
</script>
