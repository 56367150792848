<template>
  <div
    class="border rounded p-2 hover:bg-gray-100 transition-all bg-red-100"
    v-if="user.disabled"
  >
    <h2 class="font-bold">
      {{ user.firstName }} {{ user.lastName }} <span class="text-xs">(deaktiviert)</span>
    </h2>
    <p>{{ user.email }}</p>
  </div>
  <div class="border rounded p-2 hover:bg-gray-100 transition-all" v-else>
    <h2 class="font-bold">{{ user.firstName }} {{ user.lastName }}</h2>
    <p>{{ user.email }}</p>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
